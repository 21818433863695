export default {
  "menu": {
    "register": "Sign up",
    "company_info": "Company info",
    "overview": "Overview",
    "product_management": "Product management",
    "product_list": "Product list",
    "product_edit": "Modify product info",
    "product_create": "Create product info",
    "product_inventory": "Product inventory",
    "storage_management": "SE Stock Warehouse",
    "storage_inventory": "Stock warehouse inventory",
    "storage_in_warehouse_plan": "Inbound plan",
    "storage_out_plan": "Outbound plan",
    "storage_out_plan_details": "Outbound plan details",
    "storage_create_out_plan": "Create outbound plan",
    "storage_add_box_info": "Add carton info",
    "storage_confirm_out_plan": "Confirm outbound plan",
    "consignment_send_management": "SE Distribution Warehouse",
    "consignment_in_plan": "Inbound plan",
    "consignment_inventory": "Inventory in distribution warehouse",
    "consignment_order_list": "Distribution Order",
    "consignment_order_create": "Create Distribution Order",
    "consignment_order_add_product": "Add Product",
    "consignment_order_select_logistics": "Select Logistics",
    "consignment_order_details": "Distribution order details",
    "consignment_order_bulk_import": "批量导入配送单",
    "consignment_allot_plans": "Allocation plan to stock warehouse",
    "consignment_allot_create": "Create allocation plan",
    "consignment_allot_select_gooods": "Select product and packaging",
    "consignment_allot_set_box_number": "Input packing quantity",
    "consignment_allot_plan_details": "Allocation plan details",
    "consignment_return_channel_list": "Return Plan",
    "consignment_return_channel_create": "Create Return Plan",
    "consignment_return_channel_detail": "Return Plan Details",
    "transport_manage": "SE Logistics",
    "transport_plan": "Shipping plan",
    "transport_create_plan": "Create shipping plan",
    "transport_set_plan_info": "Input shipping info",
    "transport_confirm_plan": "Confirm shipping plan",
    "transport_plan_details": "Shipping plan details",
    "transport_appointment_list": "FCL Inbound Reservation",
    "transport_appointment_create": "Create Reservation",
    "transport_appointment_details": "FCL Inbound Reservation Details",
    "account": "Account",
    "account_info": "Account info",
    "account_restpwd": "Reset password",
    "about": "About",
    "sdas": "SDAS",
    "finance": "Finance",
    "finance_inventory": "Expense statement",
    "finance_details": "Expense details",
    "finance_account_balance": "Account balance",
    "recharge_record": "Recharge record",
    "finance_bill": "Bill of month",
    "customer_service": "Customer service",
    "inbound_survey": "Exceptional Stocking In Investigation",
    "print_waybill": "打单服务",
    "print_waybill_list": "运单列表",
    "print_waybill_create": "创建运单",
    "print_waybill_confirm": "选择渠道",
    "print_waybill_details": "运单详情",
    "authorization_manage": "授权管理",
    "authorization_manage_list": "授权列表",
    "auth": "授权",
    "user_management": "用户管理",
    "user_role_list": "角色管理",
    "user_account_list": "账户管理",
    "user_account_details": "账户详情",
    "user_address": "地址管理"
  }
}